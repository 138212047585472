import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {authRef} from '../reference';

import marketLogo from '../images/sohookd-marketplace-1200x208.png'

export default class Landing extends Component {
    componentDidMount() {
        authRef.onAuthStateChanged(user => {
            if (user) {
                browserHistory.push('/home');
            }
        });
    }

    handleClick = (event) => {
        window.location = 'https://marketplace.sohookd.com'
    };

    render() {
        return (
            <div className="Landing" style={{textAlign: 'center'}}>
                <img src={marketLogo} width={380} alt=""/>
                <h1>Invalid URL</h1>
                <p className="error">{"You have entered invalid url. Copy correct url from the email you received."}</p>
                <br/>
                <input
                    type="submit"
                    value="Go To SoHookd Marketplace"
                    onClick={this.handleClick}
                />
            </div>
        );
    }
}
