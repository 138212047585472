import React, {Component} from 'react';
import Spinner from 'react-spinkit';
import {authRef, functionsRef} from '../reference';
import marketLogo from "../images/sohookd-marketplace-1200x208.png";

export default class RecoverEmail extends Component {
    state = {
        error: '',
        restoredEmail: '',
        resetSent: false,
        validCode: null,
        verifiedCode: false,
    }

    componentDidMount() {
        // Confirm the action code is valid.
        authRef
            .checkActionCode(this.props.actionCode)
            .then(info => {
                // Get the restored email address.
                const restoredEmail = info['data']['email'];
                // Revert to the old email.
                authRef
                    .applyActionCode(this.props.actionCode)
                    .then(() => {
                        // Account email reverted to restoredEmail
                        this.setState({restoredEmail, validCode: true, verifiedCode: true});
                    });
            }, error => {
                // Invalid code.
                this.setState({error: error.message, validCode: false, verifiedCode: true});
            });
    }

    sendReset = () => {
        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        let passwordReset = functionsRef.httpsCallable('passwordReset');
        passwordReset({email: this.state.restoredEmail})
            .then(result => {
                // Password reset confirmation sent. Ask user to check their email.
                this.setState({resetSent: true});
            })
            .catch(reason => {

            });
    };

    handleClick = (event) => {
        window.location = 'https://marketplace.sohookd.com'
    };


    render() {
        const {
            error,
            restoredEmail,
            resetSent,
            validCode,
            verifiedCode,
        } = this.state;

        let component;
        if (!verifiedCode) {
            component = <Spinner name="three-bounce"/>;
        } else if (resetSent) {
            component = (
                <div className="RecoverEmail">
                    <h1>Check your email</h1>
                    <p>Follow the instructions sent to <span>{restoredEmail}</span> to recover your password.</p>
                    <br/>
                    <button onClick={this.handleClick}>Go To SoHookd Marketplace</button>
                </div>
            );
        } else if (verifiedCode && validCode) {
            component = (
                <div className="RecoverEmail">
                    <h1 className={"success-h1"}>Updated Email Address</h1>
                    <p>Your sign-in email address has been changed back to <span>{restoredEmail}</span></p>
                    <p>If you did not change your sign-in email,
                        it is possible someone is trying to access your account and you should change your password
                        right away.
                    </p>
                    <button onClick={this.sendReset}>Change Password</button>
                    <br/>
                    <p>OR</p>
                    <br/>
                    <button onClick={this.handleClick}>Go To SoHookd Marketplace</button>
                </div>
            );
        } else if (verifiedCode && !validCode) {
            component = (
                <div className="RecoverEmail">
                    <h1>Unable to update your email address</h1>
                    <p>There was a problem changing your sign-in email back.</p>
                    <p className="error">{error}</p>
                    <br/>
                    <button onClick={this.handleClick}>Go To SoHookd Marketplace</button>
                </div>
            );
        }

        return (
            <div style={{textAlign: 'center'}}>
                <img src={marketLogo} width={380} alt=""/>
                {component}
            </div>
        );
    }
}
