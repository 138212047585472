import React from 'react';
import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import marketLogo from "../images/sohookd-marketplace-1200x208.png";

// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props) => {

    // Get the action to complete.
    const mode = props.location.query.mode;

    // Get the one-time code from the query parameter.
    const actionCode = props.location.query.oobCode;

    // (Optional) Get the API key from the query parameter.
    // const apiKey = props.location.query.apiKey;

    const continueUrl = props.location.query.continueUrl;

    const secondCode = props.location.query.shsCode;

    const autoRedirect = props.location.query.redirect;

    // Handle the user management action.
    switch (mode) {
        case 'recoverEmail':
            // Display reset password handler and UI.
            return <RecoverEmail
                actionCode={actionCode}
                continueUrl={continueUrl}
                secondCode={secondCode}
                autoRedirect={autoRedirect}/>;
        case 'resetPassword':
            // Display email recovery handler and UI.
            return <ResetPassword
                actionCode={actionCode}
                continueUrl={continueUrl}
                secondCode={secondCode}
                autoRedirect={autoRedirect}/>;
        case 'verifyEmail':
            // Display email verification handler and UI.
            return <VerifyEmail
                actionCode={actionCode}
                continueUrl={continueUrl}
                secondCode={secondCode}
                autoRedirect={autoRedirect}/>;
        default:
            // Error: invalid mode.
            return (
                <div className="Action" style={{textAlign: 'center'}}>
                    <img src={marketLogo} width={380} alt=""/>
                    <h1>Error encountered</h1>
                    <p>The selected page mode is invalid.</p>
                </div>
            );
    }
}

export default Action;
