import React, {Component} from 'react';
import Spinner from 'react-spinkit';
import {authRef, functionsRef, recaptchaKey} from '../reference';
import marketLogo from "../images/sohookd-marketplace-1200x208.png";
import ReCaptcha from 'react-recaptcha';

export default class ResetPassword extends Component {
    state = {
        email: null,
        error: '',
        password: '',
        success: false,
        validCode: null,
        verifiedCode: false,
        recaptchaReady: false,
        recaptchaValue: null,
        loading: false
    }


    componentDidMount() {

        if (!this.props.actionCode) {
            this.setState({error: 'Invalid Link entered', validCode: false, verifiedCode: true});
            return
        }
        // Verify the password reset code is valid.
        authRef
            .verifyPasswordResetCode(this.props.actionCode)
            .then(email => {
                this.setState({email, validCode: true, verifiedCode: true});
            }, error => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                // console.log(error);
                this.setState({error: error.message, validCode: false, verifiedCode: true});
            });
    }

    onRecaptchaSuccess = (event) => {
        // console.log("event", event);
        this.setState({recaptchaValue: event});

    };

    handleResetPassword = (event) => {
        event.preventDefault();
        const {actionCode} = this.props;
        const newPassword = this.state.password;

        if (!this.state.recaptchaValue) {
            this.setState({error: 'reCAPTCHA is missing'});
            return;
        }
        this.setState({loading: true});
        let resetRequest = functionsRef.httpsCallable('resetPasswordRequest');

        resetRequest({oobCode: actionCode, newPassword, recaptcha: this.state.recaptchaValue})
            .then(value => {
                console.log(value);
                // Password reset has been confirmed and new password updated.
                this.setState({loading: false, success: true, recaptchaValue: null});
            })
            .catch(reason => {
                // console.log(reason.code, reason.message, reason.details);
                if (reason.code) {
                    switch (reason.code) {
                        case 'permission-denied':
                            this.setState({loading: false, error: 'reCAPTCHA failed. Verify again'});
                            break;
                        case 'failed-precondition':
                            let message = '';
                            switch (reason.details.error.message) {
                                case 'INVALID_OOB_CODE':
                                case 'EXPIRED_OOB_CODE':
                                    message = 'The action code is invalid. This can happen if the code is malformed, ' +
                                        'expired, or has already been used.';
                                    break;
                                default:
                                    message = 'Error, Please try again.'
                            }
                            this.setState({loading: false, error: message});
                            break;
                        default:
                            this.setState({loading: false, error: reason.message});
                    }
                } else {
                    // Error occurred during confirmation. The code might have expired or the
                    // password is too weak.
                    this.setState({loading: false, error: reason.message});
                }

                this.recaptchaRef.reset();

            });
    };

    setText = (evt) => {
        this.setState({password: evt.target.value});
    };

    handleContinueClick = () => {
        if (this.props.continueUrl) {
            window.location = this.props.continueUrl;
        } else
            window.location = 'https://marketplace.sohookd.com'
    };

    render() {
        const {
            email,
            error,
            password,
            success,
            validCode,
            verifiedCode,
            loading
        } = this.state;

        let component;
        if (!verifiedCode) {
            component = <Spinner name="three-bounce"/>;
        }
        else if (loading) {
            component = (
                <div>
                    <Spinner name="three-bounce"/>
                    <h1>Processing, Please Wait.</h1>
                </div>

            )
        }
        else if (success) {
            component = (
                <div className="ResetPassword">
                    <h1 className={"success-h1"}>Password changed</h1>
                    <p>You can now sign in with your new password</p>
                    <br/>
                    <input
                        type="submit"
                        value="Go to SoHookd Marketplace"
                        onClick={this.handleContinueClick}
                    />
                </div>
            );
        } else if (verifiedCode && validCode) {
            component = (
                <div className="ResetPassword">
                    <h1>Reset your password</h1>
                    <div>for <span>"{email}"</span></div>
                    <br/>

                    <form onSubmit={this.handleResetPassword}>

                        {error ? <p className="error">{error}</p> : ''}

                        <input
                            onChange={this.setText}
                            value={password}
                            type="password"
                            placeholder="New password"
                            minLength={'6'}
                            required/>

                        <br/>
                        <div className={'ReCaptcha'}>
                            <ReCaptcha
                                ref={(ref) => this.recaptchaRef = ref}
                                sitekey={recaptchaKey}
                                render="explicit"
                                verifyCallback={this.onRecaptchaSuccess}
                                onloadCallback={() => {
                                    console.log('onLoadCallback called');
                                    this.setState({recaptchaReady: true})
                                }}
                                expiredCallback={() => {
                                    this.setState({recaptchaValue: null})
                                }}
                            />
                        </div>
                        <br/>

                        <input
                            type="submit"
                            value="SAVE"/>
                    </form>
                </div>
            );
        } else if (verifiedCode && !validCode) {
            component = (
                <div className="ResetPassword">
                    <h1>Try resetting your password again</h1>
                    <p className="error">{error}</p>

                    <br/>
                    <input
                        type="submit"
                        value="Go to SoHookd Marketplace"
                        onClick={this.handleContinueClick}
                    />
                </div>
            );
        }
        return (
            <div style={{textAlign: 'center'}}>
                <img src={marketLogo} width={380} alt=""/>
                {component}
            </div>
        );
    }
}
