import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, IndexRoute, browserHistory} from 'react-router';
import Action from './components/Action';
import App from './components/App';
import Landing from './components/Landing';

import './index.css';

ReactDOM.render(
    <Router history={browserHistory}>

        <Route path="/" exact component={App}>
            <IndexRoute component={Landing}/>
            <Route path="/action" component={Action}/>
            {/*For all other paths*/}
            <Route path="*" component={Landing}/>
        </Route>


    </Router>,
    document.getElementById('root')
);
