import * as firebase from 'firebase/app';

import 'firebase/auth'
import 'firebase/functions'

import env from './environment';

firebase.initializeApp({
    apiKey: env.apiKey,
    authDomain: env.authDomain,
    databaseURL: env.databaseURL,
    storageBucket: env.storageBucket,
    messagingSenderId: env.messagingSenderId,
    projectId:env.projectId
});
export const authRef = firebase.auth();
export const functionsRef = firebase.functions();

export const recaptchaKey= env.recaptchaKey;
