import React, {Component} from 'react';
import Spinner from 'react-spinkit';
import {authRef} from '../reference';
import marketLogo from "../images/sohookd-marketplace-1200x208.png";

export default class VerifyEmail extends Component {
    state = {
        error: '',
        validCode: null,
        verifiedCode: false,
        passwordReset: false
    };

    handleContinueClick = () => {
        // if we have continueUrl then we redirect to it.
        if (this.props.continueUrl)
            window.location = this.props.continueUrl;
        else
            window.location = 'https://marketplace.sohookd.com'
    };

    // we have second code and user has clicked on password link button
    handlePasswordClick = () => {
        // create url chunk to simulate password reset url.
        let redirectToPassword = `/action?mode=resetPassword&oobCode=${this.props.secondCode}&`;
        if (this.props.continueUrl)
            redirectToPassword += `continueUrl=${this.props.continueUrl}&`;
        if (this.props.autoRedirect) {
            redirectToPassword += `redirect=${this.props.autoRedirect}`;
        }
        window.location = redirectToPassword;

    };


    componentDidMount() {
        if (!this.props.actionCode) {
            this.setState({error: 'Invalid Link entered', validCode: false, verifiedCode: true});
            return
        }

        // Try to apply the email verification code.
        authRef
            .applyActionCode(this.props.actionCode)
            .then(() => {
                // Email address has been verified.

                // check if second code for password reset is present or not.
                if (this.props.secondCode) {
                    // second code is present, verify that code is valid to use
                    authRef
                        .verifyPasswordResetCode(this.props.secondCode)
                        .then(() => {

                            // password button can be shown now. Also update the other states.
                            this.setState({
                                validCode: true,
                                verifiedCode: true,
                                passwordReset: true
                            });
                        }, (error) => {
                            console.log(error);

                        });
                }
                else
                    this.setState({validCode: true, verifiedCode: true});

            }, error => {

                // email code is invalid

                // check if second code for password reset present or not.
                if (this.props.secondCode) {

                    // second code is present, verify that code is valid to use
                    authRef
                        .verifyPasswordResetCode(this.props.secondCode)
                        .then(() => {

                            // password button can be shown now. Also update the other states.
                            this.setState({
                                error: error.message,
                                validCode: false,
                                verifiedCode: true,
                                passwordReset: true
                            });

                        }, (errorP) => {
                            // password code is not valid, email code was already invalid and we have redirect option.
                            if (this.props.continueUrl && this.props.autoRedirect && this.props.autoRedirect === '1')
                                window.location = this.props.continueUrl;
                            else
                                // we don't have redirect url only update state with error message.
                                this.setState({error: error.message, validCode: false, verifiedCode: true});

                        });
                }
                // only auto redirect to continue url if present and asked as query param
                else if (this.props.continueUrl && this.props.autoRedirect && this.props.autoRedirect === '1')
                    window.location = this.props.continueUrl;
                else
                    this.setState({error: error.message, validCode: false, verifiedCode: true});
            });
    }

    render() {
        const {
            error,
            validCode,
            verifiedCode,
        } = this.state;

        let passwordButton = (
            <div>
                <br/>
                < br/>
                <p>Update your password to make your account more secure.</p>
                < br/>
                < input
                    type="submit"
                    value="Attach Password to Your Account"
                    onClick={this.handlePasswordClick}
                />
            </div>

        );

        let component;
        if (!verifiedCode) {
            component = <Spinner name="three-bounce"/>;
        } else if (verifiedCode && validCode) {
            component = (
                <div className="VerifyEmail" style={{textAlign: 'center'}}>
                    <h1 className={"success-h1"}>Your email has been verified</h1>
                    <p>You can now sign in with your new account</p>
                    <br/>
                    <input
                        type="submit"
                        value="Go to SoHookd Marketplace"
                        onClick={this.handleContinueClick}
                    />
                    {this.state.passwordReset ? passwordButton : null}

                </div>
            );
        } else if (verifiedCode && !validCode) {
            component = (
                <div className="VerifyEmail" style={{textAlign: 'center'}}>
                    <h1>Try verifying your email again</h1>
                    <p className="error">{error}</p>
                    <br/>
                    <input
                        type="submit"
                        value="Go to SoHookd Marketplace"
                        onClick={this.handleContinueClick}
                    />

                    {this.state.passwordReset ? passwordButton : null}
                </div>
            );
        }

        return (
            <div style={{textAlign: 'center'}}>
                <img src={marketLogo} width={380} alt=""/>
                {component}
            </div>
        );
    }
}
